import { ADMIN_API_PATH } from '../constants';
import formDataRequest from '../helpers/axios/formDataRequest';
import axios from '../helpers/axios';

/**
 * Create staff
 * @param {*} params
 * @returns Promise
 */
const createStaff = async (params) => {
    try {
        const response = await axios.post(ADMIN_API_PATH.ADMIN_STAFF, params);
        return response;

    } catch (error) {
        console.log(error);
        throw error.response;
    }
}

/**
 * Get room information
 * @param {*} file
 * @param {*} facilityId
 * @return Promise
 */
const uploadStaff = async (facilityId, file) => {
    const response = await formDataRequest.post(`${ADMIN_API_PATH.ADMIN_STAFF}/import-staff-registration/${facilityId}`, { file: file });
    return response;
}

/**
 * Get staff information
 * @param {*} params
 */
const getStaff = async (params) => {
    try {
        const response = await axios.get(`${ADMIN_API_PATH.ADMIN_STAFF}`, {params});
        return response;
    } catch (err) {
        console.log(`get staff info`,`${ADMIN_API_PATH.ADMIN_STAFF}`, JSON.stringify(err));
        return null;
    }
}

/**
 * Update staff
 * @param {*} staffId
 * @param {*} params
 * @returns Promise
 */
const updateStaff = async (staffId, params) => {
    try {
        const response = await axios.put(`${ADMIN_API_PATH.ADMIN_STAFF}/${staffId}`, params);
        return response;
    } catch (err) {
        console.log(`update staff`, `${ADMIN_API_PATH.ADMIN_STAFF}/${staffId}`, JSON.stringify(err));
        throw err.response;
    }
}

/**
 * Update staff
 * @param {*} params
 * @returns Promise
 */
const graphDetail = async (params) => {
    try {
        const response = await axios.get(`${ADMIN_API_PATH.ADMIN_STAFF}/graph`, { params })
        return response
    } catch (error) {
        console.log(`Graph`, error);
    }

}

/**
 * Delete a staff
 * @param {*} Staffid
 * @returns Promise
 */
const deleteStaff = async (Staffid) => {
    try {
        return await axios.delete(`${ADMIN_API_PATH.ADMIN_STAFF}/${Staffid}`);
    } catch (err) {
        console.log(`delete staff`, `${ADMIN_API_PATH.ADMIN_STAFF}/${Staffid}`, JSON.stringify(err));
        return err.response
    }
}

export const staffService = {
    createStaff,
    getStaff,
    deleteStaff,
    updateStaff,
    graphDetail,
    uploadStaff,
}
