import {ADMIN_API_PATH} from '@/constants';
import axios from '../helpers/axios';

/**
 * Create SAUNA facility facility-facility-account
 * @param {*} facility
 * @returns Promise
 */
const create = async (facility) => {
  return await axios.post(ADMIN_API_PATH.ADMIN_FACILITIES, facility);
}

/**
 * Get facility information
 * @param {*} facilityId
 */
const getAdminFacility = async (facilityId) => {
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityId}`);
}

/**
 * Get facility information
 * @param {*} facilityId
 */
const getFacility = async (facilityId) => {
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityId}`);
}

/**
 * Get facility information
 */
const getMyFacility = async () => {
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/my-facility`);
}

/**
 * Update facility
 * @param {*} facilityId
 * @param {*} facility
 * @returns Promise
 */
const updateFacility = async (facilityId, facility) => {
  return await axios.put(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityId}`, facility);
}

/**
 * Update ads
 * @param {*} facilityId
 * @param {*} facility
 * @returns Promise
 */
const updateAds = async (facilityId, facility) => {
  return await axios.put(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityId}/add-advertisement`, facility);
}

/**
 * Update facility
 * @param {*} facilityId
 * @param {*} facility
 * @returns Promise
 */
const updateMyFacility = async (facility) => {
  return await axios.patch(`${ADMIN_API_PATH.ADMIN_FACILITIES}/my-facility`, facility);
}

/**
 * Delete a facility
 * @param {*} facilityId
 * @returns Promise
 */
const deleteFacility = async (facilityId) => {
  return await axios.delete(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityId}`);
}

/**
 * Get facilities
 * @param {*} facilityFilter
 * @returns Promise
 */
const getFacilities = async (facilityFilter) => {
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}`, {params: facilityFilter});
}

/**
 * Create SAUNA facility facility-facility-account
 * @param {*} services
 * @returns Promise
 */
const updateFacilityServices = async (services) => {
  return await axios.post(`${ADMIN_API_PATH.ADMIN_FACILITIES_SERVICES}/update-many`, services);
}
/**
 * Create SAUNA facility facility-facility-account
 * @param {*} services
 * @param {*} facilityID
 * @returns Promise
 */
const updateFacilityServicesByFacilityID = async (services, facilityID) => {
  return await axios.post(`${ADMIN_API_PATH.ADMIN_FACILITIES_SERVICES}/update-many/${facilityID}`, services);
}


/**
 * Get facility information
 * @param {*} params
 */
const getPostalCodeInfo = async (params) => {
  return await axios.get(`/zip-cloud`, {params: params});
}
/**
 * Get facility information
 * @param {string} type
 */
const exportFacilities = async (type = '') => {
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/export?type=${type}`)
}

const exportFacilitiesRoom = async () => {
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/export-rooms`)
}

/**
 * Get facility information
 * @param {Object} param
 */

const createFacility = async (param) => {
  return await axios.post(`${ADMIN_API_PATH.ADMIN_FACILITIES}`, param)
}

/**
 * Get all facilities for options
 */
const getOptionsAllFacility = async (param) => {
  const query = new URLSearchParams(param);
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/get-facilities-of-user?${query}`)
}

/**
 * Get all facilities for options
 */
const getFacilitiesCreateByTablet = async (param) => {
  const query = new URLSearchParams(param);
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/user-facilities?${query}`);
}

/**
 * Approve facility created by tablet
 * @param {String} facilityId
 */
const approveFacility = async (facilityId) => {
  return await axios.patch(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityId}/approve-facility`)
}

const getFacilityAppById = async (facilityId) => {
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/request/${facilityId}`)
}

const changeStatusFacilityApp = async (facilityRequestId, payload) => {
  return await axios.patch(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityRequestId}/change-status`, payload)
}

const deleteFacilityApplication = async (facilityRequestId) => {
  return await axios.delete(`${ADMIN_API_PATH.ADMIN_FACILITIES}/request/${facilityRequestId}`)
}

const updateFacilityApplicationById = async (facilityRequestId, payload) => {
  return await axios.put(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityRequestId}/update-facility-request`, payload)
}

const updateAdsForFacility = async (facilityId, payload) => {
  return await axios.put(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityId}/add-playlist`, payload)
}

const getRoomsOfFacility = async (facilityId) => {
  return await axios.get(`${ADMIN_API_PATH.ADMIN_FACILITIES}/${facilityId}/rooms`)
}

const seedHoliday = async () => {
  return await axios.post(`${ADMIN_API_PATH.ADMIN_HOLIDAY}`)
}
export const facilityService = {
  getFacilities,
  create,
  getFacility,
  getMyFacility,
  getAdminFacility,
  deleteFacility,
  updateFacility,
  updateMyFacility,
  updateFacilityServices,
  getPostalCodeInfo,
  updateFacilityServicesByFacilityID,
  exportFacilities,
  exportFacilitiesRoom,
  createFacility,
  getOptionsAllFacility,
  getFacilitiesCreateByTablet,
  approveFacility,
  updateAds,
  getFacilityAppById,
  changeStatusFacilityApp,
  deleteFacilityApplication,
  updateFacilityApplicationById,
  updateAdsForFacility,
  getRoomsOfFacility,
  seedHoliday
}
