import { ADMIN_API_PATH } from "../constants";
import axios from "../helpers/axios";

/**
 * Config system
 * @param {*} config
 * @returns Promise
 */
const setConfigSystem = async (config) => {
  return await axios.post(ADMIN_API_PATH.ADMIN_SYSTEM, config);
};

const getSystemConfig = async () => {
  return await axios.get(ADMIN_API_PATH.ADMIN_SYSTEM);
};

const getAuthSystemConfig = async (headers) => {
    console.log(headers);
  return await axios.get(ADMIN_API_PATH.ADMIN_SYSTEM, { headers });
};

export const systemService = {
  setConfigSystem,
  getSystemConfig,
  getAuthSystemConfig
};
