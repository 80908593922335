import { ADMIN_API_PATH } from "../constants";
import axios from "../helpers/axios";

/**
 * Get detail sns information by facility
 * @param {String} facilityId
 * @returns
 */
const getSnsByFacilityId = async (facilityId) => {
  try {
    const response = await axios.get(
      `${ADMIN_API_PATH.ADMIN_SNS}/show/facility/${facilityId}`
    );
    return response;
  } catch (err) {
    console.log("getSnsByFacilityId", err);
    throw err.response;
  }
};

const getSnsSession = async (facilityId) => {
  return await axios.get(
    `${ADMIN_API_PATH.ADMIN_SNS}/user/${facilityId}`
  );
};

const pushSnsPost = async (facilityId, params) => {
  try {
    const response = await axios.post(
      `${ADMIN_API_PATH.ADMIN_SNS}/save/facility/${facilityId}`,
      params
    );
    return response;
  } catch (err) {
    console.log(err, "pushSnsPost");
    throw err.response;
  }
};

const deleteSnsSession = async (facilityId) => {
  try {
    const response = await axios.delete(
      ADMIN_API_PATH.ADMIN_SNS + `/${facilityId}`
    );
    return response;
  } catch (err) {
    console.log(err, "deleteSnsSession");
    throw err.response;
  }
};

/**
 * Connect SNS
 * @returns
 */
const connectSns = async () => {
  try {
    const response = await axios.get(
      `${ADMIN_API_PATH.ADMIN_SNS}/twitter/connect`
    );
    return response;
  } catch (e) {
    console.log(e, "connectSns");
    return null;
  }
};

/**
 * Connect Sns callback
 * @returns
 */
const connectSnsCallback = async () => {
  try {
    const response = await axios.get(
      `${ADMIN_API_PATH.ADMIN_SNS}/twitter/callback`
    );
    return response;
  } catch (e) {
    console.log(e, "connectSnsCallback");
    return null;
  }
};

/**
 * 
 * @param {String} facilityId 
 * @param {*} params 
 * @returns 
 */
const manualPost = async (facilityId, params) => {
  try {
    const response = await axios.post(
      `${ADMIN_API_PATH.ADMIN_SNS}/post-twitter/facility/${facilityId}`,
      params
    );
    return response;
  } catch (err) {
    console.log(err, "manualPost");
    throw err.response;
  }
};

export const snsService = {
  getSnsByFacilityId,
  pushSnsPost,
  connectSns,
  connectSnsCallback,
  deleteSnsSession,
  manualPost,
  getSnsSession
};
