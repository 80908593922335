import { ADMIN_API_PATH } from '../constants';
import axios from '../helpers/axios';

const getPublicAPIList = async (params) => {
    try {
        const response = await axios.get(ADMIN_API_PATH.ADMIN_PUBLIC_API, {params})
        return response
    } catch(e) {
        console.log('getPublicAPIList', e)
        throw e.response
    }
}

const viewPublicAPI = async(apiId) => {
    try {
        const response = await axios.get(`${ADMIN_API_PATH.ADMIN_PUBLIC_API}/${apiId}`)
        return response
    } catch(e) {
        console.log('viewPublicAPI', e)
        throw e.response
    }
}

const createPublicAPI = async (body) => {
    try {
        const response = await axios.post(ADMIN_API_PATH.ADMIN_PUBLIC_API, body)
        return response
    } catch(e) {
        console.log('createPublicAPI', e)
        throw e.response
    }
}

const updatePublicAPI = async (apiId, body) => {
    try {
        const response = await axios.put(`${ADMIN_API_PATH.ADMIN_PUBLIC_API}/${apiId}`, body)
        return response
    } catch(e) {
        console.log('updatePublicAPI', e)
        throw e.response
    }
}

const deletePublicAPI = async (apiId) => {
    try {
        const response = await axios.delete(`${ADMIN_API_PATH.ADMIN_PUBLIC_API}/${apiId}`)
        return response
    } catch(e) {
        console.log('deletePublicAPI', e)
        throw e.response
    }
} 

export const publicApiService = {
    getPublicAPIList,
    viewPublicAPI,
    createPublicAPI,
    deletePublicAPI,
    updatePublicAPI
}