import { ADMIN_API_PATH } from '../constants';
import axios from '../helpers/axios';

const getCouponList = async (params) => {
    try {
        const response = await axios.get(ADMIN_API_PATH.ADMIN_COUPON, {params})
        return response
    } catch(e) {
        console.log('getCouponList', e)
        throw e.response
    }
}

const viewCoupon = async(couponId) => {
    try {
        const response = await axios.get(`${ADMIN_API_PATH.ADMIN_COUPON}/${couponId}`)
        return response
    } catch(e) {
        console.log('viewCoupon', e)
        throw e.response
    }
}

const createCoupon = async (body) => {
    try {
        const response = await axios.post(ADMIN_API_PATH.ADMIN_COUPON, body)
        return response
    } catch(e) {
        console.log('createCoupon', e)
        throw e.response
    }
}

const updateCoupon = async (id, body) => {
    try {
        const response = await axios.put(`${ADMIN_API_PATH.ADMIN_COUPON}/${id}`, body)
        return response
    } catch(e) {
        console.log('updateCoupon', e)
        throw e.response
    }
}

const deleteCoupon = async (couponId) => {
    try {
        const response = await axios.delete(`${ADMIN_API_PATH.ADMIN_COUPON}/${couponId}`)
        return response
    } catch(e) {
        console.log('createCoupon', e)
        throw e.response
    }
} 

export const couponService = {
    getCouponList,
    viewCoupon,
    createCoupon,
    deleteCoupon,
    updateCoupon
}