import { ADMIN_API_PATH } from '../constants';
import axios from '../helpers/axios';


const getListWhitelist = async(params) => {
    return await axios.get(ADMIN_API_PATH.ADMIN_WHITELIST, {params})
}

const createWhitelist = async(body) => {
    return await axios.post(ADMIN_API_PATH.ADMIN_WHITELIST, body)
}

const updateWhitelist = async(whitelistId, body) => {
    return await axios.put(`${ADMIN_API_PATH.ADMIN_WHITELIST}/${whitelistId}`, body)
}

const deleteWhitelist = async(whitelistId) => {
    return await axios.delete(`${ADMIN_API_PATH.ADMIN_WHITELIST}/${whitelistId}`)
}

const toggleWhitelist = async (body) => {
    return await axios.put(`${ADMIN_API_PATH.ADMIN_WHITELIST}/toggle`, body)
}

const getToggleWhitelist = async () => {
    return await axios.get(`${ADMIN_API_PATH.ADMIN_WHITELIST}/toggle`)
}

export const whitelistService = {
    getListWhitelist,
    createWhitelist,
    updateWhitelist,
    deleteWhitelist,
    toggleWhitelist,
    getToggleWhitelist
}