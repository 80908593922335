import {API_PATH} from '@/constants';
import axios from '../helpers/axios';

const createPushNotification = async (payload) => {
    return await axios.post(API_PATH.NOTIFICATION, payload);
}

const deletePushNotification = async (id) => {
    return await axios.delete(`${API_PATH.NOTIFICATION}/${id}`);
}

// alert

const getDetailAlertByFacility = async (facilityId) => {
    return await axios.get(`${API_PATH.ALERT}/${facilityId}`);
}
const updateAlertByFacility = async (facilityId, payload) => {
    return await axios.post(`${API_PATH.ALERT}/${facilityId}`, payload)
}

const regisDeviceToken = async (payload) => {
    return await axios.post(`/device-token`, payload)
}

const getAllAlert = async (params) => {
    return await axios.get(`notifications/notification-detail`, {params})
}

const readAlert = async (notificationId) => {
    return await axios.get(`notifications/notification-detail/${notificationId}`)
}

const deleteNotificationByIds = async (ids) => {
    return await axios.post(`${API_PATH.NOTIFICATION}/notification-detail/delete`, ids);
}

export const pushNotificationService = {
    createPushNotification,
    deletePushNotification,
    getDetailAlertByFacility,
    updateAlertByFacility,
    regisDeviceToken,
    getAllAlert,
    deleteNotificationByIds,
    readAlert
}
