import Request from './request.js';
import * as OSS from 'ali-oss';
import { CONTENT_TYPE } from "@/constants";

const API_ROOT = process.env.VUE_APP_SAUNA_URL;
const formDataRequest = new Request(API_ROOT, CONTENT_TYPE.FORMDATA);

export const clientOSS = new OSS({
    accessKeyId: process.env.VUE_APP_ACCESS_KEY_ID_OSS,
    accessKeySecret: process.env.VUE_APP_ACCESS_KEY_SECRET_OSS,
    bucket: process.env.VUE_APP_BUCKET_OSS,
    endpoint: process.env.VUE_APP_ENDPOINT_OSS
});

const typePass = ['image/jpeg', 'image/png', 'image/jpg'];
export const sliceFileName = (name, start = 5, end = 10) => {
    if (name.length <= (start + end)) {
        return name;
    }

    const first = name.substring(0, start);
    const last = name.substring(name.length - end, name.length);

    return `${first}...${last}`
}
export const validateExtension = (file) => {
    const fileName = sliceFileName(file.name);

    if (typePass.findIndex(item => item === file.type) < 0) {
        return {
            message: '',
            validate: false,
            fileName: fileName,
            type: 'extensionNotSupport'
        }
    }

    if (file.size > 10000000) {
        return {
            message: '',
            validate: false,
            fileName: fileName,
            type: 'sizeLage'
        }
    }

    if (file.size <= 0) {
        return {
            message: '',
            validate: false,
            fileName: fileName,
            type: 'sizeZero'
        }
    }

    return {
        message: '',
        validate: true,
        fileName: ''
    };
}

export const generateImageOss = (name) => {
    return clientOSS.signatureUrl(name, {
        expires: 3600,
        method: 'GET'
    })
}

export default formDataRequest;
